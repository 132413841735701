import React from "react";
import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Image, Input, Layout, message, Modal, Row, Select, Space, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';
import PageLoad from '../../Controle/PageLoad';
import { IoAdd, IoTrash } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        _navigate: false,
        _loading: true,
        _screen: false,
        _select: [],
        data: [],
        defaultValue: {
            ID: 0,
        }
    }

    componentDidMount = () => {
        this.onView()
        this.onLoadPhoto()
    }

    onLoadPhoto = () => {
        POST_API('/cupons/list_photo.php', { token: getToken(), ID: this.state._params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({data: res.data})
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({table__load: false}))
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: 'Carregando...', key: 'screen' })
        POST_API('/cupons/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.destroy('screen')
                // message.success({ content: res.msg, key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/cupons/photos.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'send' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'send' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    onDelete = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Excluir foto?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/cupons/del_photo.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onLoadPhoto()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    onSelect = async (value) => {
        var temp = this.state._select
        if (temp.includes(value)) {
            temp.splice(temp.indexOf(value), 1)
        } else {
            temp.push(value)
        }
        return temp
    }

    onSelectDelete = () => {
        var self = this
        Modal.confirm({
            title: `Excluir ${self.state._select.length} fotos?`,
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/cupons/select_del_photo.php', { token: getToken(), REG: self.state._select }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onLoadPhoto()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/cupons/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/cupons">{'Cupons'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Galeria'}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.defaultValue.ID}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/cupons"><Button type="primary" shape="round" size="small" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} defaultValue={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,8]}>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Selecionar Fotos" name="IMAGES" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Upload
                                            action={UPLOAD_API}
                                            listType="picture-card"
                                            onPreview={(file) => window.open(file.response.url)}
                                        >
                                            <div>
                                                <IoAdd />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="primary" className="btn-primary">{'Enviar Fotos'}</Button>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Divider>Galeria</Divider>
                                </Col>
                                <Col span={24}>
                                    <Button onClick={this.onSelectDelete} disabled={this.state._select.length == 0} type="default" shape="round" >Excluir Selecionados ({this.state._select.length})</Button>
                                </Col>
                                <Col span={24}>
                                {this.state.data.length > 0 ? (
                                    <Row gutter={[8,8]}>
                                        {this.state.data.map((v, i) => (
                                            <Col key={i} xs={24} md={6}>
                                                <Card style={{backgroundColor: 'var(--gray)'}} hoverable cover={<Image preview={false} src={v.URL} />}>
                                                    <Row justify='space-between'>
                                                        <Checkbox onChange={() => this.onSelect(v.ID).then(rs => this.setState({_select: rs}))} />
                                                        <Button onClick={() => this.onDelete(v.ID)} className="table__btn table__btn_delete" title={'Excluir'} type="primary" shape="circle" size="small"><IoTrash size={14} /></Button>
                                                    </Row>
                                                </Card>
                                            </Col>
                                        ))}
                                    </Row>
                                ) : (
                                    <Typography style={{textAlign: 'center'}}>Nenhuma foto encontrada</Typography>
                                )}
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;