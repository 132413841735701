import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';
import PageLoad from '../../Controle/PageLoad';

import { BsFacebook, BsInstagram, BsLink, BsTwitter, BsYoutube } from 'react-icons/bs';
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        defaultValue: {
            ID: 0,
        }
    }

    componentDidMount = () => {
        this.onView()
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: 'Carregando...', key: 'screen' })
        POST_API('/empresas/view.php', { token: getToken(), ID: params[5] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.destroy('screen')
                // message.success({ content: res.msg, key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/empresas/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'send' })
                this.onView()
            } else {
                message.warning({ content: res.msg, key: 'send' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/parceiros/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/parceiros">{'Parceiros'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Atualizar'}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state.defaultValue.ID}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/parceiros"><Button type="primary" shape="round" size="small" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} defaultValue={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                            <Col xs={24} md={8}>
                                    <Row>
                                        <Col span={24}>
                                            <Typography>Logo</Typography>
                                            <Image style={{marginBottom: 10, borderRadius: 8}} preview={false} width={'100%'} src={this.state.defaultValue.LOGO ? this.state.defaultValue.LOGO : "https://madeirasgasometro.vteximg.com.br/arquivos/ids/168849-1000-1000/mdf-cinza-sagrado-essencial-imagem-01.jpg?v=636655430222730000"} />
                                            <Form.Item name="LOGO">
                                                <Upload accept="image/jgp,image/jpeg,image/png" action={UPLOAD_API} maxCount={1} className="sys-ant-upload">
                                                    <Button block type="primary" className="btn-primary">{'Selecionar nova logo'}</Button>
                                                </Upload>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={16}>
                                    <Row gutter={[4,4]}>
                                        <Col xs={24} md={16}>
                                            <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="CNPJ" name="CNPJ" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                <ReactInputMask mask="99.999.999/9999-99" className="ant-input css-dev-only-do-not-override-j0nf2s"   />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="E-mail" name="EMAIL" rules={[{type: 'email', message: 'E-mail inválido'}]}>
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Telefone" name="PHONE">
                                                <Input type='text' />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item label="Apresentação" name="DESCRIPTION">
                                                <Input.TextArea type='text' rows={5} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Facebook" name="FACEBOOK">
                                                <Input type='text' prefix={<BsFacebook />} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item label="Instagram" name="INSTAGRAM">
                                                <Input type='text' prefix={<BsInstagram />} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Twitter" name="TWITTER">
                                                <Input type='text' prefix={<BsTwitter />} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Youtube" name="YOUTUBE">
                                                <Input type='text' prefix={<BsYoutube />} />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={8}>
                                            <Form.Item label="Site" name="WEB">
                                                <Input type='text' prefix={<BsLink />} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="btn-default float-right">{'Salvar'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;