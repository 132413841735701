
export const URL_API = "https://amikacupon.com.br/server/php";
export const UPLOAD_API = "https://amikacupon.com.br/server/php/upload/upload.php";

export const setToken = (value) => {
    localStorage.setItem('TOKEN', value);
}

export const getToken = () => {
    return localStorage.getItem('TOKEN');
}

export const delToken = () => {
    localStorage.removeItem('TOKEN')
}

export const setConfig = (value) => {
    localStorage.setItem('CONFIG', value);
}

export const getConfig = () => {
    return localStorage.getItem('CONFIG');
}

export const delConfig = () => {
    localStorage.removeItem('CONFIG')
}

export const verifyConfig = (value) => {
    return (getConfig() === value)
}

export const POST_API = (url, data) => {

    function createFormData () {
        const form = new FormData()
    
        Object.keys(data).forEach(key => {
            form.append(key, data[key]);
        });
      
        return form;
    };
  
    return fetch(URL_API+url, { method: "post", body: createFormData() })

}
