import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.setState({_loading: false})
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/clientes/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/clientes/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/clientes">{'Clientes'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Adicionar'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/clientes"><Button type="primary" size="small" shape="round" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[16,0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="CPF" name="CPF">
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="btn-default float-right">{'Salvar'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;