import React from "react";
import { Button, Col, Form, Image, Layout, message, Row, Input, Carousel } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { getToken, POST_API, setToken, setConfig } from "../../services/server-api";

// IMAGES
import logo from '../../assets/imgs/logo-laranja.png';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true
    }

    componentDidMount = () => {
        if (getToken()) {
            message.loading({ content: 'Carregando...', key: 'login' })
            POST_API('/credential/verify.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({_navigate: true})
                } else {
                    message.warning({ content: res.msg, key: 'login' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
            }).finally(() => {
                this.setState({_loading: false})
            })
        }
        this.setState({_loading: false})
    }

    onSend = (values) => {

        this.setState({_loading: true})
        message.loading({ content: 'Carregando...', key: 'login' })

        POST_API('/credential/login.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                setToken(res.token)
                setConfig(res.type)
                message.success({ content: res.msg, key: 'login' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'login' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
        }).finally(() => {
            this.setState({_loading: false})
        })

    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="dashboard" />);
        }

        return (
            <Content>
                <Row className="container-login" align="middle" justify="center">
                    <Col className="container-login-fields" xs={20} md={8}>
                        <Image src={logo} preview={false} width={175} />
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                <Input placeholder="Digite seu e-mail" type="text"/>
                            </Form.Item>
                            <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite sua senha" type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state._loading} className="btn-primary" htmlType="submit" block type="primary">Entrar</Button>
                                <Link to="cadastrar"><Button className="btn-link" block type="link">Cadastrar-se</Button></Link>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col className="container-login-ads" xs={20} md={8}>
                        <Carousel className="carousel">
                            <div>
                                <h3 className="content-style">1</h3>
                            </div>
                            <div>
                                <h3 className="content-style">2</h3>
                            </div>
                            <div>
                                <h3 className="content-style">3</h3>
                            </div>
                            <div>
                                <h3 className="content-style">4</h3>
                            </div>
                        </Carousel>
                    </Col>
                    <Col className="container-login-cel" span={24}>
                        <Image src={logo} preview={false} />
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                <Input placeholder="Digite seu e-mail" type="text"/>
                            </Form.Item>
                            <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite sua senha" type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state._loading} className="btn-primary" htmlType="submit" block type="primary">Entrar</Button>
                                <Link to="cadastrar"><Button className="btn-link" block type="link">Cadastrar-se</Button></Link>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;