import React from "react";
import { Modal, Button, Col, Drawer, Image, Layout, Row, Skeleton } from 'antd';
import { Link, Navigate, Outlet } from "react-router-dom";
import { BiMenu } from 'react-icons/bi';
import { ExclamationCircleOutlined, UserOutlined, BellOutlined, LogoutOutlined } from '@ant-design/icons';
import { delToken, getToken, delConfig } from "../../../services/server-api";


import Page403 from '../../Controle/Page403';
import Sidebar from '../../../components/Sidebar';

import logo from '../../../assets/imgs/logo-laranja.png';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _menu: false,
        _perfil: false,
        _notification: false
    }

    onLogOut = () => {
        var self = this
        Modal.confirm({
            title:'Sair do sistema?',
            icon: <ExclamationCircleOutlined />,
            cancelText:'Não',
            okText:'Sim',
            className: 'sys-modal-confirm',
            onOk() { delToken(); delConfig(); self.setState({_navigate: true}) },
            onCancel() {},
        })
    }

    onMenu = () => {
        this.setState({_menu: !this.state._menu})
    }

    onPerfil = () => {
        this.setState({_perfil: !this.state._perfil})
    }

    onNotification = () => {
        this.setState({_notification: !this.state._notification})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content>
                <Row style={{height: '100vh', backgroundColor: '#eeeeee', alignContent: 'flex-start'}}>
                    <Col xs={0} md={4} className="sys-sidebar">
                        <Row gutter={[16,16]}>
                            <Col span={24}>
                                <Link to='/dashboard'><Image width={'100%'} src={logo} preview={false}/></Link>
                            </Col>
                        </Row>
                        <Sidebar />
                    </Col>
                    <Col xs={24} md={20} >
                        <Row style={{height: '100vh', width: '100%', backgroundColor: '#eeeeee', alignContent: 'flex-start', overflow: 'hidden'}}>
                            <Col className="sys-navbar" xs={24} md={0}>
                                <Row className="sys-navbar-row" justify="space-between" align="center">
                                    <Button shape="circle" type="text" onClick={this.onMenu}><BiMenu size={20} /></Button>
                                    <Link to='/dashboard'><Image src={logo} className="sys-navbar-row-logo" preview={false}/></Link>
                                    <Image preview={false} className="sys-navbar-avatar" src="https://picsum.photos/200" size={30} onClick={this.onPerfil} placeholder={<Skeleton.Avatar active={true} />} />
                                </Row>
                            </Col>
                            <Col className="sys-navbar" xs={0} md={24}>
                                <Row className="sys-navbar-row" justify="end" align="center">
                                    {/* <Link to='/dashboard'><Image src={logo} className="sys-navbar-row-logo" preview={false}/></Link> */}
                                    <Image preview={false} className="sys-navbar-avatar" src="https://picsum.photos/200" onClick={this.onPerfil} placeholder={<Skeleton.Avatar active={true} />} />
                                </Row>
                            </Col>
                            <Col span={24} className="sys-content">
                                <Outlet />
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Drawer closable={false} width={250} onClose={this.onMenu} open={this.state._menu} placement="left">
                    <Row gutter={[16,16]}>
                        <Col span={24}>
                            <Link to='/dashboard'><Image width={'100%'} src={logo} preview={false}/></Link>
                        </Col>
                    </Row>
                    <Sidebar />
                </Drawer>
                <Drawer closable={false} width={250} onClose={this.onPerfil} open={this.state._perfil} placement="right">
                    <Row gutter={[8,8]}>
                        <Col span={24} style={{marginBottom: 20}}>
                            <Image preview={false} className="sys-navbar-avatar-perfil" src="https://picsum.photos/200" size={30} onClick={this.onPerfil} placeholder={<Skeleton.Avatar active={true} style={{width: 200, height: 200}} />} />
                        </Col>
                        <Col span={24}>
                            <Link to="/dashboard/perfil"><Button block icon={<UserOutlined />} type="text" className="sys-btn-text">{'Meu perfil'}</Button></Link>
                        </Col>
                        <Col span={24}>
                            <Button block icon={<LogoutOutlined />} onClick={this.onLogOut} type="text" className="sys-btn-text">{'Sair'}</Button>
                        </Col>
                    </Row>
                </Drawer>
                <Drawer closable={false} width={250} onClose={this.onNotification} open={this.state._notification} placement="right">
                    
                </Drawer>
            </Content>
        )
    }

}

export default Screen;