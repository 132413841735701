import { Avatar, Button, Col, Modal, Image, Input, List, Row, Typography, Form, Tabs, Space, Divider } from "antd";
import React from "react";

// IMAGES
import logo from '../assets/imgs/logo-laranja.png';

// ICONS
import { IoAlbumsOutline, IoPersonCircleOutline, IoCompassOutline, IoHomeOutline, IoLogIn, IoLogoWhatsapp, IoMenu, IoPerson, IoPersonOutline, IoPhoneLandscapeOutline, IoPricetagsOutline, IoSearch } from 'react-icons/io5';
import { Link } from "react-router-dom";

import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLink } from 'react-icons/fa';

class Screen extends React.Component {

    state = { select: '', _open: false }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/'); 
        this.onSelect(values[3] != '' ? values[3] : 'home')

    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    onSendRegister = (values) => {
        
    }

    render = () => {

        return (
            <Col span={24} className="amika-div-footer">
                <Row gutter={[16,16]} className="amika-footer">
                    <Col xs={24} md={8}>
                        <Typography style={{textAlign: 'center', marginBottom: 6, fontSize: 18, fontWeight: 600, color: 'var(--primary)'}}>Sobre nós</Typography>
                        <center><Button href="#sobre" type="link" className="btn-link">Quem somos?</Button></center>
                        <center><Button href="#nossoparceiro" type="link" className="btn-link">Seja nosso parceiro</Button></center>
                    </Col>
                    <Col xs={24} md={8}>
                        <Typography style={{textAlign: 'center', marginBottom: 6, fontSize: 18, fontWeight: 600, color: 'var(--primary)'}}>Termos</Typography>
                        <center><Button type="link" className="btn-link">Termos de Uso</Button></center>
                        <center><Button type="link" className="btn-link">Politica de Privacidade</Button></center>
                    </Col>
                    <Col xs={24} md={8}>
                        <Typography style={{textAlign: 'center', marginBottom: 6, fontSize: 18, fontWeight: 600, color: 'var(--primary)'}}>Dúvidas</Typography>
                        <center><Button href="#contato" type="link" className="btn-link">Fale Conosco</Button></center>
                        <center><Button href="#perguntas" type="link" className="btn-link">FAQ Perguntas Frequentes</Button></center>
                    </Col>
                    <Col xs={24} md={12}>
                        <Typography style={{textAlign: 'center'}}>Baixe nosso aplicativo!</Typography>
                        <div className="loja-social" style={{justifyContent: 'center'}}>
                            <Image width={'100px'} preview={false} src='https://www.cuponeria.com.br/images/footer/app-android.jpg'/>
                            <Image width={'100px'} preview={false} src='https://www.cuponeria.com.br/images/footer/app-ios.jpg'  style={{marginLeft: 10}}/>
                        </div>
                    </Col>
                    <Col xs={24} md={12}>
                        <Typography style={{textAlign: 'center'}}>Nos siga nas redes sociais!</Typography>
                        <div className="loja-social" style={{justifyContent: 'center'}}>
                            <a target='_blank' rel="noreferrer" href={'#'} className="loja-social-facebook"><FaFacebookF color="#FFF" /></a>
                            <a target='_blank' rel="noreferrer" href={'#'} className="loja-social-instagram"><FaInstagram size={15} color="#FFF" /></a>
                            <a target='_blank' rel="noreferrer" href={'#'} className="loja-social-youtube"><FaYoutube size={15} color="#FFF" /></a>
                            <a target='_blank' rel="noreferrer" href={'#'} className="loja-social-twitter"><FaTwitter color="#FFF" /></a>
                        </div>
                    </Col>
                </Row>
            </Col>
        )

    }

}

export default Screen;