import React from "react";
import { Breadcrumb, Button, Col, Layout, message, Modal, Row, Tag } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { ExclamationCircleOutlined } from '@ant-design/icons';

// API
import { getToken, POST_API } from "../../../services/server-api";

// COMPONENTS
import Table from '../../../components/Table';

// CONTROLLER
import Page403 from '../../Controle/Page403';
import { IoReturnUpBackOutline } from "react-icons/io5";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        // TABLE
        table__load: true,
        table__column: [],
        table__data: [],
        table__search: '',
        table__pagination: {
            current: 1,
            total: 0,
            limit: 10
        },
        table__filter: null,
        table__sorter: null,
    }

    componentDidMount = () => {
        this.onRenderColumns().then(() => this.onRenderData())
    }

    onRecover = (ID) => {
        var self = this
        Modal.confirm({
            title: 'Recuperar registro?',
            icon: <ExclamationCircleOutlined />,
            cancelText: 'Não',
            okText: 'Sim',
            className: 'sys-modal-confirm',
            onOk() {
                POST_API('/clientes/recover.php', { token: getToken(), ID: ID }).then(rs => rs.json()).then(res => {
                    if (res.return) {
                        message.success({ content: res.msg, key: 'screen' })
                        self.onRenderData()
                    } else {
                        message.warning({ content: res.msg, key: 'screen' })
                    }
                }).catch(() => {
                    message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
                }).finally(() => self.setState({_loading: false}))
            },
            onCancel() {},
        })
    }

    // FUNCTION TABLE
    onRenderColumns = async () => {
        this.setState({
            table__column: [
                { title: 'Cod.', dataIndex: 'ID', key: 'credential.ID', width: 100, align: 'center', sorter: { compare: (a,b) => null }, showSorterTooltip: false },
                { title: 'Nome', dataIndex: `NAME`, key: `credential.NAME`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                { title: 'E-mail', dataIndex: `EMAIL`, key: `credential.EMAIL`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                { title: 'CPF', dataIndex: `CPF`, key: `credential.CPF`, sorter: { compare: (a,b) => null }, showSorterTooltip: false  },
                { title: 'Ações', key: 'action', width: 100, render: (text, record) => (
                    <Row gutter={[4,4]} className="table__actions">
                        <Col><Button onClick={() => this.onRecover(record.key)} className="table__btn table__btn_recover" title={'Recuperar'} type="primary" shape="circle" size='small'><IoReturnUpBackOutline size={14} /></Button></Col>
                    </Row>
                    ),
                },
            ]
        })
    }

    onTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            table__filter: filters,
            table__sorter: sorter
        })
        this.onRenderData()
    }

    onPaginationChange = (page, size) => {
        this.setState({table__pagination: {...this.state.table__pagination, current: page, limit: size}})
        this.onRenderData()
    }

    onSearchChange = (e) => {
        this.setState({table__search: e.target.value})
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({table__load: true})
        setTimeout(async () => {
            POST_API('/clientes/trash.php', { token: getToken(), pagination: JSON.stringify(this.state.table__pagination), filter: JSON.stringify(this.state.table__filter), sorter: JSON.stringify(this.state.table__sorter), search: this.state.table__search }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({table__data: res.data, table__pagination: { ...this.state.table__pagination, total: res.summary.QTDE }})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({table__load: false}))
        }, 500);
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={18}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/clientes">{'Clientes'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Lixeira'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={6} style={{display: 'flex', justifyContent: 'flex-end'}}>
                        <Link to='/dashboard/clientes'><Button type="primary" shape="round" size="small" className="btn-primary sys-btn-small">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Table
                            _columns={this.state.table__column}
                            _data={this.state.table__data}
                            _pagination={this.state.table__pagination}
                            _loading={this.state.table__load}
                            _onChangeTable={this.onTableChange}
                            _onChangePagination={this.onPaginationChange}
                            _onChangeSearch={this.onSearchChange}
                            _search={this.state.table__search}
                        />
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;