import { Avatar, Card, Col, message, Row, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLink } from 'react-icons/fa';


// CONTROLLER
import PartLoading from '../Controle/PartLoading';

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/empresas.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Typography className="amika-titulo-page">Nossos Parceiros</Typography>
                        <Typography className="amika-subtitulo-page">Encontre descontos nas suas lojas preferidas</Typography>
                    </Col>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,4]}>
                                {this.state.data.map((v, i) => (
                                    <Col md={8} xs={24} key={i}>
                                        <Card hoverable style={{borderRadius:15}}>
                                            <Card.Meta
                                                avatar={<Avatar className="loja-avatar" src={v.LOGO} />}
                                                title={v.NAME}
                                                description={<Row>
                                                    <Typography>{v.DESCRIPTION}</Typography>
                                                    <div className="loja-social">
                                                        { v.FACEBOOK ? <a target='_blank' rel="noreferrer" href={v.FACEBOOK} className="loja-social-facebook"><FaFacebookF color="#FFF" /></a> : null }
                                                        { v.INSTAGRAM ? <a target='_blank' rel="noreferrer" href={v.INSTAGRAM} className="loja-social-instagram"><FaInstagram size={15} color="#FFF" /></a> : null }
                                                        { v.YOUTUBE ? <a target='_blank' rel="noreferrer" href={v.YOUTUBE} className="loja-social-youtube"><FaYoutube size={15} color="#FFF" /></a> : null }
                                                        { v.TWITTER ? <a target='_blank' rel="noreferrer" href={v.TWITTER} className="loja-social-twitter"><FaTwitter color="#FFF" /></a> : null }
                                                        { v.WEB ? <a target='_blank' rel="noreferrer" href={v.WEB} className="loja-social-web"><FaLink size={15} color="#FFF" /></a> : null }
                                                    </div>
                                                </Row>}
                                            />
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;