import React from "react";
import { Avatar, Breadcrumb, Button, Card, Col, Form, Image, Input, Layout, List, message, Row, Segmented, Statistic, Table, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API, verifyConfig } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';
import PartLoading from '../../Controle/PartLoading';

import { BsFacebook, BsInstagram, BsLink, BsTwitter, BsYoutube } from 'react-icons/bs';
import ReactInputMask from "react-input-mask";
import { LineChart, CartesianGrid, XAxis, YAxis, Legend, Line, Tooltip, BarChart, Bar } from "recharts";
// import { CartesianGrid } from "recharts/types/cartesian/CartesianGrid";
class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _data_kpi: {
            CUPONS_ATIVOS: 10,
            CUPONS_EXPIRADOS: 2,
            CUPONS_VENDIDOS: 18,
            TOTAL_VISUALIZACOES: 30,
        },
        _data_chart1: null,
        _loading_values: false,
        _periodo: 'Hoje',
        _ano: 2023,
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.setState({_loading: false})
        this.onLoadChartOne()
    }

    onLoadChartOne = (periodo = this.state._periodo) => {
        this.setState({_loading_values: true})
        POST_API('/rechart/DashboardChartOne.php', { token: getToken(), periodo }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({_data_chart1: res.data, _loading_values: false})
                message.success({ content: res.msg, key: 'screen' })
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/empresas/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    onChangePeriodo = async (value) => {
        this.setState({_periodo: value})
        this.onLoadChartOne(value)
    }

    data = [
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
        {
          "CUPOM": "ABC",
          "QTDE": "3",
        },
      ]

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={12}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item>{'Dashboard'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={12} style={{display: 'flex', justifyContent: 'end'}}>
                        <Segmented value={this.state._periodo} onChange={this.onChangePeriodo} options={['Hoje', 'Semana', 'Mês']} />
                        <Input value={this.state._ano} onChange={(v) => this.setState({_ano: v.target.value})} placeholder="Ano" style={{width: 80, textAlign: 'center', marginLeft: 8}} type="number" min={2000} max={3000} />
                        {/* <Link to="/dashboard/parceiros"><Button type="primary" size="small" shape="round" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link> */}
                    </Col>
                    <Col xs={12} md={6}>
                        <Card hoverable size="small">
                            <Row justify={'center'}>
                                <Statistic style={{textAlign: 'center'}} loading={this.state._loading_values} title="Cupons Ativos" value={this.state._data_kpi.CUPONS_ATIVOS} />
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card hoverable size="small">
                            <Row justify={'center'}>
                                <Statistic valueStyle={{ color: '#be0000' }} style={{textAlign: 'center'}} loading={this.state._loading_values} title="Cupons Expirados" value={this.state._data_kpi.CUPONS_EXPIRADOS} />
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card hoverable size="small">
                            <Row justify={'center'}>
                                <Statistic valueStyle={{ color: '#57b900' }} style={{textAlign: 'center'}} loading={this.state._loading_values} title="Cupons Vendidos" value={this.state._data_kpi.CUPONS_VENDIDOS} />
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card hoverable size="small">
                            <Row justify={'center'}>
                                <Statistic style={{textAlign: 'center'}} title="Total Visualizações" loading={this.state._loading_values} value={this.state._data_kpi.TOTAL_VISUALIZACOES} />
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card hoverable size="small" title="VENDAS">
                            <Row style={{padding: 10}}>
                                {this.state._loading_values ? (<center style={{width: '100%'}}><PartLoading /></center>) : (
                                    <BarChart width={470} height={250} data={this.state._data_chart1}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Bar dataKey="Cupons" fill="#82ca9d" />
                                    </BarChart>
                                )}
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={12}>
                        <Card hoverable size="small" title="VISUALIZAÇÕES">
                            <Row justify={'center'} style={{padding: 10}}>
                                {this.state._loading_values ? (<PartLoading />) : (
                                    <LineChart width={470} height={250} data={this.state._data_chart1}
                                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <XAxis dataKey="name" />
                                        <YAxis />
                                        <Tooltip />
                                        <Legend />
                                        <Line type="monotone" dataKey="Loja" stroke="#8884d8" />
                                        <Line type="monotone" dataKey="Cupons" stroke="#82ca9d" />
                                    </LineChart>
                                )}
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card style={{textAlign: 'center'}} title="Cupons Populares" hoverable size="small" cover={
                            <Table loading={this.state._loading_values} pagination={false} dataSource={this.data} className='table__' size="small" columns={[
                                {title: 'Cupom', dataIndex: 'CUPOM', align: "left"},
                                {title: 'Visitas', dataIndex: 'QTDE', align: "right"},
                            ]} />
                        }/>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card style={{textAlign: 'center'}} title="Cupons Mais Vendidos" hoverable size="small" cover={
                            <Table loading={this.state._loading_values} pagination={false} dataSource={this.data} className='table__' size="small" columns={[
                                {title: 'Cupom', dataIndex: 'CUPOM', align: "left"},
                                {title: 'Visitas', dataIndex: 'QTDE', align: "right"},
                            ]} />
                        }/>
                    </Col>
                    <Col xs={24} md={8}>
                        <Card style={{textAlign: 'center'}} title="Clientes Frequentes" hoverable size="small" cover={
                            <Table loading={this.state._loading_values} pagination={false} dataSource={this.data} className='table__' size="small" columns={[
                                {title: 'Cupom', dataIndex: 'CUPOM', align: "left"},
                                {title: 'Visitas', dataIndex: 'QTDE', align: "right"},
                            ]} />
                        }/>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;