import { Avatar, Card, Carousel, Col, Image, message, Row, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLink } from 'react-icons/fa';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

import CuponComponent from '../../components/Cupon';

class Screen extends React.Component {

    state = {
        loading_page: true,
        empresas: [],
        cupons_dia: [],
        cupons_semana: [],
        cashback: [],
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/home.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({
                        empresas: res.data.MARCAS,
                        cupons_dia: res.data.CUPONS_DIA,
                        cupons_semana: res.data.CUPONS_SEMANA,
                        cashback: res.data.CASHBACK,
                    })
                    console.log(res.data.CASHBACK)
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[16,16]}>
                    {/* <Col span={24}>
                        <Typography className="amika-titulo-page">Amika Cupons</Typography>
                        <Typography className="amika-subtitulo-page">Economize na sua compra usando os melhores Cupons de Desconto e Cashback!</Typography>
                    </Col> */}
                    <Col span={24}>
                        <Carousel className="carousel-home" autoplay>
                            <div>
                                <h3 className="content-style-home">1</h3>
                            </div>
                            <div>
                                <h3 className="content-style-home">2</h3>
                            </div>
                            <div>
                                <h3 className="content-style-home">3</h3>
                            </div>
                            <div>
                                <h3 className="content-style-home">4</h3>
                            </div>
                        </Carousel>
                    </Col>
                    <Col span={24}>
                        <Typography style={{fontWeight: 700, textTransform: 'uppercase', marginBottom: 10}}>Melhores cupons do dia</Typography>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,4]}>
                                {this.state.cupons_dia.map((v, i) => (
                                    <Col md={6} xs={24} key={i}>
                                        <CuponComponent cod={i} data={v} group={'cupons_dia'} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                    <Col span={24}>
                        <Typography style={{fontWeight: 700, textTransform: 'uppercase', marginBottom: 10}}>Melhores cupons da semana</Typography>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,4]}>
                                {this.state.cupons_semana.map((v, i) => (
                                    <Col md={6} xs={24} key={i}>
                                        <CuponComponent cod={i} data={v} group={'cupons_semana'} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                    <Col span={24}>
                        <Typography style={{fontWeight: 700, textTransform: 'uppercase', marginBottom: 10}}>Melhores cashbacks</Typography>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,4]}>
                                {this.state.cashback.map((v, i) => (
                                    <Col md={6} xs={24} key={i}>
                                        <CuponComponent cod={i} data={v} group={'cashback'} />
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;