import { Avatar, Card, Col, message, Row, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube, FaLink } from 'react-icons/fa';


// CONTROLLER
import PartLoading from '../Controle/PartLoading';

class Screen extends React.Component {

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Typography className="amika-titulo-page">Sobre nós</Typography>
                        <Typography className="amika-subtitulo-page">Conheça um pouco sobre nossa história e objetivos</Typography>
                    </Col>
                    <Col span={24}>
                        <Card title="Quem somos?" hoverable>
                            <Typography>A AMIKA Cupom De Desconto surgiu com a ideia de ajudar as pessoas a economizar em todas as compras online. A empresa fez muito sucesso facilitando a compra para os seus clientes através de cupons de descontos e ofertas exclusivas, alcançando milhares de pessoas e cada vez mais, fidelizando novos clientes.</Typography>
                            <Typography>Somos um site de cupons de desconto com o custo de R$ 1,99. Ajudamos você a economizar quando realiza uma compra online, disponibilizamos diversos cupons de descontos e ofertas para milhares de lojas online. E não é só isso. Também oferecemos cashback, é isso mesmo! Dinheiro de volta em suas compras, muito bom, né!?</Typography>
                        </Card>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;