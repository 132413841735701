import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Segmented, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API, verifyConfig } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';
import Plataforma from './Plataforma';
import Cliente from './Cliente';

import { BsFacebook, BsInstagram, BsLink, BsTwitter, BsYoutube } from 'react-icons/bs';
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.setState({_loading: false})
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/empresas/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        if (verifyConfig('1')) {
            return (<Plataforma />)
        }

        if (verifyConfig('2')) {
            return (<Cliente />)
        }
    }

}

export default Screen;