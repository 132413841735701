import { Card, Col, Input, message, Row, Spin, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Link } from "react-router-dom";
import { getToken, POST_API } from "../../services/server-api";


// CONTROLLER
import PartLoading from '../Controle/PartLoading';

class Screen extends React.Component {

    state = {
        loading_page: true,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/categorias.php', { token: getToken() }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                <Row gutter={[4,16]}>
                    <Col span={24}>
                        <Typography className="amika-titulo-page">Categorias</Typography>
                        <Typography className="amika-subtitulo-page">Encontre seus descontos favoritos</Typography>
                    </Col>
                    <Col span={24}>
                        {this.state.loading_page ? <center><PartLoading /></center> : (
                            <Row gutter={[8,4]}>
                                {this.state.data.map((v, i) => (
                                    <Col xs={24} md={6} key={i}>
                                        <Link to={v.ID}>
                                            <Card hoverable cover={<img alt="example" src={v.IMAGE} />}>
                                                <Card.Meta style={{textAlign: 'center'}} title={v.NAME} description={v.DESCRIPTION} />
                                            </Card>
                                        </Link>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;