import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API } from "../../../../services/server-api";

// CONTROLLER
import Page403 from '../../../Controle/Page403';
import PageLoad from '../../../Controle/PageLoad';
import ReactInputMask from "react-input-mask";

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        _screen: false,
        defaultValue: {
            ID: 0,
        }
    }

    componentDidMount = () => {
        this.setState({_params: window.location.href.split('/')})
        this.onView()
    }

    onView = () => {
        var params = window.location.href.split('/')
        message.loading({ content: 'Carregando...', key: 'screen' })
        POST_API('/usuarios/view.php', { token: getToken(), ID: params[7] }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.destroy('screen')
                // message.success({ content: res.msg, key: 'screen' })
                this.setState({_screen: true, defaultValue: res.data})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            this.setState({_navigate: true})
        }).finally(() => this.setState({_loading: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})

        values.ACCOUNT_ID = this.state._params[5]

        POST_API('/usuarios/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'send' })
                this.onView()
            } else {
                message.warning({ content: res.msg, key: 'send' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={`/dashboard/parceiros/${this.state._params?.[5]}/usuarios`} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/parceiros">{'Parceiros'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state._params?.[5]}</Breadcrumb.Item>
                            <Breadcrumb.Item><Link to={`/dashboard/parceiros/${this.state._params?.[5]}/usuarios`}>{'Usuários'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Atualizar'}</Breadcrumb.Item>
                            <Breadcrumb.Item>{this.state._params?.[7]}</Breadcrumb.Item>
                        </Breadcrumb>
                        <Breadcrumb className="sys-breadcrumb">
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to={`/dashboard/parceiros/${this.state._params?.[5]}/usuarios`}><Button type="primary" size="small" shape="round" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                    {this.state._screen ? (
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue} defaultValue={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[8,0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item label="E-mail" name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={6}>
                                    <Form.Item name="CPF" label="CPF" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                        <ReactInputMask mask="999.999.999-99" className="ant-input css-dev-only-do-not-override-j0nf2s" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="btn-default float-right">{'Salvar'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    ) : <PageLoad /> }
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;