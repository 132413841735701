import React from 'react';
import './App.css';
import AppStack from './routes/AppStack';


class App extends React.Component {

  render = () => {

    return (
      <AppStack />
    )

  }

}

export default App;