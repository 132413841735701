import React from "react";
import { Breadcrumb, Button, Col, Form, Image, Input, Layout, message, Row, Select, Typography, Upload } from 'antd';
import { Link, Navigate } from "react-router-dom";

// API
import { getToken, POST_API, UPLOAD_API } from "../../../services/server-api";

// CONTROLLER
import Page403 from '../../Controle/Page403';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true,
        select__categories: [],
        defaultValue: {
            ID: 0
        }
    }

    componentDidMount = () => {
        this.setState({_loading: false})
        POST_API('/categorias/search.php', { token: getToken() }).then(rs => rs.json()).then(res => {
            if (res.return) {
                this.setState({select__categories: res.data})
            } else {
                message.error({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({table__load: false}))
    }

    onSend = (values) => {
        this.setState({_loading: true})
        POST_API('/cupons/save.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'screen' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'screen' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to={'/dashboard/cupons/'} />)
        }

        if (getToken() == null) {
            return (<Page403 />)
        }

        return (
            <Content className="sys-container">
                <Row gutter={[8,8]}>
                    <Col span={20}>
                        <Breadcrumb className="sys-breadcrumb">
                            <Breadcrumb.Item><Link to="/dashboard/cupons">{'Cupons'}</Link></Breadcrumb.Item>
                            <Breadcrumb.Item>{'Adicionar'}</Breadcrumb.Item>
                        </Breadcrumb>
                    </Col>
                    <Col span={4}>
                        <Link to="/dashboard/cupons"><Button type="primary" size="small" shape="round" className="btn-primary sys-btn-small float-right">{'Voltar'}</Button></Link>
                    </Col>
                    <Col span={24}>
                        <Form layout="vertical" className="sys-form" onFinish={this.onSend} initialValues={this.state.defaultValue}>
                            <Form.Item hidden name="ID">
                                <Input  />
                            </Form.Item>
                            <Row gutter={[16,0]}>
                                <Col xs={24} md={12}>
                                    <Form.Item label="Código" name="CODE" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={3}>
                                    <Form.Item label="Quantidade" name="QTD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='number' min={0} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4}>
                                    <Form.Item label="Situação" name="STATUS" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Select>
                                            <Select.Option value={'A'}>Ativo</Select.Option>
                                            <Select.Option value={'I'}>Inativo</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={5}>
                                    <Form.Item label="Validade" name="DATETIME_VALIDATE">
                                        <Input type='datetime-local' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={20}>
                                    <Form.Item label="Nome" name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input type='text' />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={4}>
                                    <Form.Item label="Cashback" name="CASHBACK" >
                                        <Input type='number' min={0} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Regras e Informações" name="RULES" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Input.TextArea type='text' rows={5} />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Form.Item label="Categorias" name="CATEGORIES"  rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                        <Select mode="multiple" allowClear >
                                            {this.state.select__categories.map((v, i) => (
                                                <Select.Option value={v.ID} key={i}>{v.NAME}</Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={24} md={24}>
                                    <Button loading={this.state._loading} htmlType="submit" type="default" className="btn-default float-right">{'Salvar'}</Button>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;