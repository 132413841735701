import React from "react";
import { Col, Layout, Row } from 'antd';
import { Navigate } from "react-router-dom";

import './load.scss';

class Screen extends React.Component {

    state = {
        _navigate: false
    }

    onButton = () => {
        this.setState({_navigate: true})
    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="/" />);
        }

        return (
            <Content>
                <Row style={{height: '100vh', alignItems: 'center'}} justify="center">
                    <Col span={24}>
                        <div className="loader-container">
                            <div className="loader">
                                <div></div>
                                <div></div>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;