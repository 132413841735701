import React from "react";
import { Button, Col, Form, Image, Layout, message, Row, Input, Typography } from 'antd';
import { Link, Navigate } from "react-router-dom";
import { POST_API } from "../../services/server-api";

// IMAGES
import logo from '../../assets/imgs/logo-laranja.png';

class Screen extends React.Component {

    state = {
        _navigate: false,
        _loading: true
    }

    componentDidMount = () => {
        this.setState({_loading: false})
    }

    onSend = (values) => {

        this.setState({_loading: true})
        message.loading({ content: 'Carregando...', key: 'login' })

        POST_API('/credential/registerbrand.php', { token: 'X', master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'login' })
                this.setState({_navigate: true})
            } else {
                message.warning({ content: res.msg, key: 'login' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'login' })
        }).finally(() => {
            this.setState({_loading: false})
        })

    }

    render = () => {

        const { Content } = Layout

        if (this.state._navigate) {
            return (<Navigate to="/" />);
        }

        return (
            <Content>
                <Row className="container-login" align="middle" justify="center">
                    <Col className="container-login-fields" xs={24} md={8}>
                        <Image src={logo} preview={false} />
                    </Col>
                    <Col className="container-register" xs={24} md={8}>
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Typography className="text-title">Cadastrar Marca</Typography>
                            <Form.Item name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite o nome da marca" type="text"/>
                            </Form.Item>
                            <Form.Item name="CNPJ" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite seu CNPJ" type="text"/>
                            </Form.Item>
                            <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                <Input placeholder="Digite seu e-mail" type="text"/>
                            </Form.Item>
                            <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite sua senha" type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state._loading} className="btn-primary" htmlType="submit" block type="primary">Cadastrar-se</Button>
                                <Link to="/"><Button className="btn-link" block type="link">Já possui uma conta? Clique aqui!</Button></Link>
                            </Form.Item>
                        </Form>
                    </Col>
                    <Col className="container-register-cel" xs={24} md={8}>
                        <Form layout="vertical" onFinish={this.onSend}>
                            <Image src={logo} preview={false} />
                            <Typography className="text-title">Cadastrar Marca</Typography>
                            <Form.Item name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite o nome da marca" type="text"/>
                            </Form.Item>
                            <Form.Item name="CNPJ" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite seu CNPJ" type="text"/>
                            </Form.Item>
                            <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                <Input placeholder="Digite seu e-mail" type="text"/>
                            </Form.Item>
                            <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                <Input placeholder="Digite sua senha" type="password"/>
                            </Form.Item>
                            <Form.Item>
                                <Button loading={this.state._loading} className="btn-primary" htmlType="submit" block type="primary">Cadastrar-se</Button>
                                <Link to="/"><Button className="btn-link" block type="link">Já possui uma conta? Clique aqui!</Button></Link>
                            </Form.Item>
                        </Form>
                    </Col>
                </Row>
            </Content>
        )
    }

}

export default Screen;