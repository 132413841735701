import { Breadcrumb, Button, Card, Col, Form, Input, Layout, message, Row } from "antd";
import React from "react";
import ReactInputMask from "react-input-mask";
import { getToken, POST_API } from "../../../services/server-api";
import PageLoad from '../../Controle/PageLoad';


class Screen extends React.Component {

    state = {
        data: null,
        _loading: false
    }

    componentDidMount = () => {
        this.onLoadUser()
    }

    onLoadUser = () => {
        POST_API('/credential/load.php', {token: getToken()}).then(rs => rs.json()).then(res => {
            this.setState({data: res.data})
        })
    }

    onSendData = (values) => {
        this.setState({_loading: true})
        POST_API('/credential/perfil.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'send' })
                this.onLoadUser()
            } else {
                message.warning({ content: res.msg, key: 'send' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    onSendPassword = (values) => {
        this.setState({_loading: true})
        POST_API('/credential/password.php', { token: getToken(), master: JSON.stringify(values) }).then(rs => rs.json()).then(res => {
            if (res.return) {
                message.success({ content: res.msg, key: 'send' })
                this.onLoadUser()
            } else {
                message.warning({ content: res.msg, key: 'send' })
            }
        }).catch(() => {
            message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'send' })
        }).finally(() => this.setState({_loading: false}))
    }

    render = () => {

        const { Content } = Layout

        return (
            <Content className="sys-container">
                {this.state.data == null ? (<PageLoad />) : (
                    <Row gutter={[8,8]}>
                        <Col span={24}>
                            <Breadcrumb className="sys-breadcrumb">
                                <Breadcrumb.Item>Meu Perfil</Breadcrumb.Item>
                            </Breadcrumb>
                        </Col>
                        <Col xs={24} md={16}>
                            <Form layout="vertical" onFinish={this.onSendData} initialValues={this.state.data}>
                                <Card title="Meu dados" hoverable size="small" actions={[<Button type="primary" className="btn-primary" htmlType="submit" loading={this.state._loading}>Salvar</Button>]}>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="NAME" label="Nome" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="EMAIL" label="E-mail" rules={[{required: true, message: 'Campo obrigatório'}, {type: 'email', message: 'E-mail inválido'}]}>
                                                <Input />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={12}>
                                            <Form.Item name="CPF" label="CPF" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                <ReactInputMask mask="999.999.999-99" className="ant-input css-dev-only-do-not-override-j0nf2s" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Form>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form layout="vertical" onFinish={this.onSendPassword}>
                                <Card title="Mudar Senha" hoverable size="small" actions={[<Button type="primary" className="btn-primary" htmlType="submit" loading={this.state._loading}>Salvar</Button>]}>
                                    <Form.Item hidden name="ID">
                                        <Input />
                                    </Form.Item>
                                    <Row gutter={[8,8]}>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="OLD_PASSWORD" label="Senha atual" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                <Input type="password" />
                                            </Form.Item>
                                        </Col>
                                        <Col xs={24} md={24}>
                                            <Form.Item name="NEW_PASSWORD" label="Nova senha" rules={[{required: true, message: 'Campo obrigatório'}]}>
                                                <Input type="password" />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                )}
            </Content>
        )
    }
    
}

export default Screen;