import { Avatar, Button, Card, Col, message, Row, Space, Spin, Tag, Typography } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { getToken, POST_API } from "../../services/server-api";

// COMPONENT
import CuponComponent from '../../components/Cupon';

// CONTROLLER
import PartLoading from '../Controle/PartLoading';

class Screen extends React.Component {

    state = {
        _params: window.location.href.split('/'),
        loading_page: true,
        category: null,
        data: []
    }

    componentDidMount = () => {
        this.onRenderData()
    }

    setFlip = (key) => {
        if (document.querySelector(`#flipper_${key}`).classList.contains('active')) {
            document.querySelector(`#flipper_${key}`).classList.remove('active')
        } else {
            document.querySelector(`#flipper_${key}`).classList.add('active')
        }
    }

    onRenderData = () => {
        this.setState({loading_page: true})
        setTimeout(async () => {
            POST_API('/loja/categoria_cupons.php', { token: getToken(), CATEGORY_ID: this.state._params[4] }).then(rs => rs.json()).then(res => {
                if (res.return) {
                    this.setState({data: res.data, category: res.category})
                } else {
                    message.error({ content: res.msg, key: 'screen' })
                }
            }).catch(() => {
                message.error({ content: 'Não foi possível estabelecer uma conexão com o sistema', key: 'screen' })
            }).finally(() => this.setState({loading_page: false}))
        }, 500);
    }

    render = () => {
        return (
            <Content>
                {this.state.loading_page ? <center><PartLoading /></center> : (
                <Row gutter={[4,16]}>
                        <Col span={24}>
                            <Typography className="amika-titulo-page">{this.state.category.NAME}</Typography>
                            <Typography className="amika-subtitulo-page">{this.state.category.DESCRIPTION}</Typography>
                        </Col>
                        <Col span={24}>
                            <Row gutter={[8,4]}>
                                {this.state.data.map((v, i) => (
                                    <Col xs={24} md={6} key={i}>
                                        <CuponComponent cod={i} data={v} />
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                )}
            </Content>
        )
    }

}

export default Screen;