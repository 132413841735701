import { Avatar, Button, Card, Col, Row, Space, Tag, Typography } from "antd";
import React from "react";
import { Link } from "react-router-dom";

class Screen extends React.Component {

    setFlip = (cod, group) => {
        if (document.querySelector(`#flipper_${cod}_${group}`).classList.contains('active')) {
            document.querySelector(`#flipper_${cod}_${group}`).classList.remove('active')
        } else {
            document.querySelector(`#flipper_${cod}_${group}`).classList.add('active')
        }
    }

    componentDidMount = () => {}

    render = () => {

        const {data, cod, group} = this.props

        return (
            <div className="flip-container">
                <Card hoverable id={`flipper_${cod}_${group}`} className="flipper">
                    <Row className="front" gutter={[8,8]} justify={'space-between'}>
                        <Avatar size={50} src={data.LOGO_ACCOUNT} />
                        <Typography>{data.NAME_ACCOUNT}</Typography>
                        <Col span={24} style={{height: 100, overflowY: 'scroll'}}>
                            <Typography>{data.NAME}</Typography>
                            {data.CASHBACK > 0 ? <Typography style={{color: 'var(--primary)', fontWeight: 600}}>{data.CASHBACK}% de cashback</Typography> : null}
                        </Col>
                        <Col span={24} style={{alignSelf: 'end'}}>
                            <Space style={{marginBottom: 5}} size={[0, 2]} wrap>
                                {data.CATEGORIAS.map((cat_v, cat_i) => (
                                    <Link to={`/categorias/${cat_v.CATEGORY_ID}`}><Tag cod={cat_i}>{cat_v.CATEGORY_NAME}</Tag></Link>
                                ))}
                            </Space>
                            <Button className="btn-primary" block type="primary">Pegar Cupom</Button>
                            <Button className="btn-link" block type="link" onClick={() => this.setFlip(cod, group)}>Regras e Informações</Button>
                        </Col>
                    </Row>
                    <Row className="back" gutter={[8,8]} justify={'space-between'}>
                        <Avatar size={50} src={data.LOGO_ACCOUNT} />
                        <Typography>{data.NAME_ACCOUNT}</Typography>
                        <Col span={24} style={{height: 100, overflowY: 'scroll'}}>
                            <Typography>{data.RULES}</Typography>
                        </Col>
                        <Col span={24} style={{alignSelf: 'end'}}>
                            <Space style={{marginBottom: 5}}  size={[0, 2]} wrap>
                                {data.CATEGORIAS.map((cat_v, cat_i) => (
                                    <Link to={`/categorias/${cat_v.CATEGORY_ID}`}><Tag cod={cat_i}>{cat_v.CATEGORY_NAME}</Tag></Link>
                                ))}
                            </Space>
                            <Button className="btn-primary" block type="primary">Pegar Cupom</Button>
                            <Button className="btn-link" block type="link" onClick={() => this.setFlip(cod, group)}>Voltar</Button>
                        </Col>
                    </Row>
                </Card>
            </div>
        )
    }

}

export default Screen;