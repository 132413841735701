import React from "react";
import { Col, Form, Row, Typography, Pagination, Table, Input } from "antd";

class Component extends React.Component {

    render = () => {

        const { _columns, _data, _onChangeTable, _onSend, _loading, _onChangePagination, _pagination, _onChangeSearch, _search, _selectRows, _setSelectRows } = this.props
        const rowSelection = (_selectRows && _setSelectRows) ? {selectedRowKeys: _selectRows, onChange: _setSelectRows} : false;

        return (
            <Row className="table__row_table" gutter={[8,8]}>
                <Col span={24}>
                    <Row>
                        <Col span={12} style={{display: 'flex', alignItems: 'center'}}>
                            <Typography className="table__summary">{'Total de'} {_pagination.total} {'registros retornados'}</Typography>
                        </Col>
                        <Col span={12}>
                            <Form layout="vertical" onFieldsChange={_onSend} className="table__form_filter">
                                <Form.Item name="search">
                                    <Input onChange={_onChangeSearch} placeholder={"Pesquisar..."} className="table__form_filter_input" value={_search} />
                                </Form.Item>
                            </Form>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Table
                        pagination={false}
                        onChange={_onChangeTable}
                        columns={_columns}
                        dataSource={_data} 
                        loading={_loading}
                        scroll={{x: true}}
                        rowSelection={rowSelection}
                        className='table__'
                    />
                </Col>
                {_pagination?.current > 0 ? (
                    <Col span={24}>
                        <Row justify="end">
                            <Pagination current={_pagination.current} defaultCurrent={_pagination.current} total={_pagination.total} onChange={_onChangePagination} showSizeChanger/>
                        </Row>
                    </Col>
                ) : null }
            </Row>
        )
    }
    
}

export default Component;