import { Avatar, Button, Col, Modal, Image, Input, List, Row, Typography, Form, Tabs } from "antd";
import React from "react";

// IMAGES
import logo from '../assets/imgs/logo-laranja.png';

// ICONS
import { IoAlbumsOutline, IoPersonCircleOutline, IoCompassOutline, IoHomeOutline, IoLogIn, IoLogoWhatsapp, IoMenu, IoPerson, IoPersonOutline, IoPhoneLandscapeOutline, IoPricetagsOutline, IoSearch } from 'react-icons/io5';
import { Link } from "react-router-dom";

class Screen extends React.Component {

    state = { select: '', _open: false }

    componentDidMount = () => {
        
        const params = window.location.href;
        const values = params.split('/'); 
        this.onSelect(values[3] != '' ? values[3] : 'home')

    }

    onSelect = (page) => {
        this.setState({select: page})
    }

    onSendRegister = (values) => {
        
    }

    render = () => {

        return (
            <Col span={24} className="amika-div-bars">
                <Row className="amika-navbar" align='middle' justify='space-between'>
                    <Col md={6} xs={0}>
                        <Image src={logo} className="amika-logo-bar" preview={false}/>
                    </Col>
                    <Col md={12} xs={0}>
                        <Input.Search allowClear placeholder="O que você procura?" />
                    </Col>
                    <Col md={6} xs={0}>
                        <List className="amika-text-div" onClick={() => this.setState({_open: true})}>
                            <List.Item>
                                <List.Item.Meta
                                    avatar={ <IoPersonCircleOutline className="amika-text-icon" size={30} />}
                                    title={<Typography className="amika-text-login">Faça Login</Typography>}
                                    description={<Typography className="amika-text-register">ou Cadastre-se</Typography>}
                                />
                            </List.Item>
                        </List>
                    </Col>
                </Row>
                <Row className="amika-navbar-secondary" align='middle' justify='center'>
                    <Link to="/"><Button className={this.state.select === "home" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("home")} type="link"><IoHomeOutline className="mr-5" />Home</Button></Link>
                    <Link to="/explorar"><Button className={this.state.select === "explorar" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("explorar")} type="link"><IoCompassOutline className="mr-5" />Explorar</Button></Link>
                    <Link to="/categorias"><Button className={this.state.select === "categorias" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("categorias")} type="link"><IoPricetagsOutline className="mr-5" />Categorias</Button></Link>
                    <Link to="/parceiros"><Button className={this.state.select === "parceiros" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("parceiros")} type="link"><IoAlbumsOutline className="mr-5" />Nossos Parceiros</Button></Link>
                    <Link to="/sobre"><Button className={this.state.select === "sobre" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("sobre")} type="link"><IoPersonOutline className="mr-5" />Sobre Nós</Button></Link>
                    <Link to="/contato"><Button className={this.state.select === "contato" ? "amika-item-menu btn-link active" : "amika-item-menu btn-link"} onClick={() => this.onSelect("contato")} type="link"><IoLogoWhatsapp className="mr-5" />Entre em Contato Conosco</Button></Link>
                </Row>
                <Modal style={{top: 10}} open={this.state._open} onCancel={() => this.setState({_open: false})} footer={false}>
                    <Row>
                        <Col span={24}>
                            <Tabs defaultActiveKey="1" centered >
                                <Tabs.TabPane tab="Fazer Login" key="1">
                                    <Form layout="vertical" onFinish={null}>
                                        <Row gutter={[8,0]} justify='center'>
                                            <Col span={12}>
                                                <Image src={logo} preview={false}/>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                                    <Input placeholder="Digite seu e-mail" type="text"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite sua senha" type="password"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Button style={{float: 'right'}} htmlType="submit" type="primary" className="btn-primary">Entrar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                                <Tabs.TabPane tab="Cadastre-se" key="2">
                                    <Form layout="vertical" onFinish={this.onSendRegister}>
                                        <Row gutter={[8,0]} justify='center'>
                                            <Col span={12}>
                                                <Image src={logo} preview={false}/>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name="NAME" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite seu nome" type="text"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name="EMAIL" rules={[{required: true, message: 'Campo obrigatório*'}, {type: 'email', message: 'E-mail inválido'}]}>
                                                    <Input placeholder="Digite seu e-mail" type="text"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Form.Item name="PASSWORD" rules={[{required: true, message: 'Campo obrigatório*'}]}>
                                                    <Input placeholder="Digite sua senha" type="password"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={18}>
                                                <Button style={{float: 'right'}} htmlType="submit" type="primary" className="btn-primary">Cadastrar-se</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Tabs.TabPane>
                            </Tabs>
                        </Col>
                    </Row>
                </Modal>
            </Col>
        )

    }

}

export default Screen;