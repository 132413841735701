import { Col, Divider, Row } from "antd";
import { Content } from "antd/es/layout/layout";
import React from "react";
import { Outlet } from "react-router-dom";

// COMPONENTS
import NavBarComponent from '../../components/NavBarComponent';
import FooterComponent from '../../components/FooterComponent';

class Screen extends React.Component {

    render = () => {
        return (
            <Content style={{overflow: 'hidden'}}>
                <Row>
                    <NavBarComponent />
                    <Col span={24} className="amika-container">
                        <Outlet />
                    </Col>
                    <FooterComponent />
                </Row>
            </Content>
        )
    }

}

export default Screen;